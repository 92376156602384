<template>
    <div>
        <b-form @submit.prevent.stop="setUrl" inline>
            <b-input-group>
                <b-form-input
                    size="sm"
                    type="url"
                    v-model="url"
                    style="min-width: 200px"
                ></b-form-input>
                <b-input-group-addon>
                    <b-dropdown id="dropdown-1" text="" class="m-0" size="sm">
                        <b-dropdown-item v-for="(resource, index) in resources" :key="index" @click="url = resource">{{ resource }}</b-dropdown-item>
                    </b-dropdown>
                </b-input-group-addon>
                <b-input-group-append>
                    <b-button type="submit" variant="orange" size="sm"><font-awesome-icon icon="check"/></b-button>
                </b-input-group-append>
            </b-input-group>
        </b-form>
    </div>
</template>

<script>
export default {
    name: "apiChanger",
    data: () => ({
        url: '',
        resources: ['http://172.22.120.85/itrepo/api', 'http://localhost:80/itrepo/api', process.env.VUE_APP_API]
    }),
    mounted() {
        this.url = this.$store.getters.getApi;
    },
    methods: {
        setUrl(){
            this.$store.dispatch('setApi', this.url);
            console.log(this.url);
            this.$toastr.s("Api URL erfolgreich geändert! Zum resetten bitte den Local Storage leeren.")
        },
    }
};
</script>

<style scoped>

</style>
