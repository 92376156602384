<template>
    <div class="locale-changer">
        <CDropdown
            placement="bottom-end"
        >
            <template #toggler>
                <b-button :variant="variant">
                    <CIcon style="margin-top: -1px" size="lg" :content="langs.find(l => l.value === $i18n.locale).flag"/>
                    {{ langs.find(l => l.value === $i18n.locale).name }}
                </b-button>
            </template>
            <CDropdownItem v-for="(lang, i) in langs" :key="`Lang${i}`" @click="change(lang.value)">
                <CIcon size="lg" :content="lang.flag" class="mb-1"/>
                {{ lang.name }}
            </CDropdownItem>
        </CDropdown>
    </div>
</template>

<script>
import {cifDe, cifGb} from '@coreui/icons';
import {localize} from "vee-validate";

export default {
    name: 'locale-changer',
    props: {
        variant: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            langs: [{
                value: 'de',
                name: 'Deutsch',
                flag: cifDe
            }, {
                value: 'en',
                name: 'English',
                flag: cifGb
            }]
        };
    },
    methods: {
        change(lang) {
            this.$i18n.locale = lang;
            localize(lang);
            localStorage.setItem("locale", lang);
        }
    }
};
</script>
