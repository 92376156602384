<template>
    <CHeader fixed with-subheader light>
        <CToggler
            in-header
            class="ml-3 d-lg-none"
            @click="$store.commit('toggleSidebarMobile')"
        />
        <CToggler
            in-header
            class="ml-3 d-md-down-none"
            @click="$store.commit('toggleSidebarPool')"
        />
        <CHeaderNav class="d-md-down-none mr-auto">
            <CHeaderNavItem>
            </CHeaderNavItem>
            <api-changer v-if="developerMode()"></api-changer>
        </CHeaderNav>
        <CHeaderNav class="mr-4">
            <CHeaderNavItem class="px-3">
                <b-button variant="light" @click.prevent.stop="onSync">
                    <font-awesome-icon icon="sync" :spin="sync"/>
                </b-button>
            </CHeaderNavItem>
            <locale-changer variant="light"/>
            <CDropdown add-toggler-classes="header-dropdown">
                <template #toggler-content>
                    <CHeaderNavLink style="font-size: 0.8rem; float: left; display: block; text-align: left">
                        <div>{{ fullName }}</div>
                        <div style="font-size: smaller">{{ role }}</div>
                    </CHeaderNavLink>
                </template>
                <CDropdownItem @click.prevent.stop="logout">
                    <CIcon name="cil-lock-locked"/>
                    {{ $t("buttons.logout") }}
                </CDropdownItem>
            </CDropdown>
        </CHeaderNav>
        <CSubheader class="px-3">
            <MyBreadcrumbRouter class="border-0 mb-0"/>
        </CSubheader>
    </CHeader>
</template>

<script>
import LocaleChanger from "../components/locale-changer";
import MyBreadcrumbRouter from "./MyBreadcrumbRouter";
import userService from "../services/userService";
import ApiChanger from "../components/apiChanger";
import {EventBus} from "../services/EventBus";
import mainServices from "../services/mainServices";

export default {
    name: 'TheHeader',
    components: {
        ApiChanger,
        MyBreadcrumbRouter,
        LocaleChanger
    },
    data: () => ({
        sync: false,
        locale: 'de'
    }),
    computed: {
        fullName() {
            return this.$store.getters["user/firstName"] + " " + this.$store.getters["user/lastName"];
        },
        role() {
            return this.$store.getters["user/roles"][0].name[this.locale];
        }
    },
    watch: {
        '$i18n.locale': function (newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.onSync();
        this.locale = mainServices.getLocale();
    },
    methods: {
        developerMode() {
            return !!localStorage.getItem("developer");
        },
        onSync() {
            if (!this.sync) {
                EventBus.$emit('sync');
                this.sync = true;
                userService.get(this.$store.getters["user/id"]).then(response => {
                    this.$store.dispatch("user/setUser", response.data).then();
                    this.sync = false;
                }).catch(error => {
                    this.sync = false;
                    this.errorHandler(error);
                });
            }
        },
        logout() {
            userService.logout(this);
        }
    }
};
</script>
<style>
.dropdown-toggle.header-dropdown::after {
    margin-top: 1rem;
}
</style>
