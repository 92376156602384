<template>
    <CSidebar
        fixed
        :minimize="minimize"
        :show="show"
        @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
    >
        <CSidebarBrand class="d-md-down-none" style="justify-content: left;" to="/">
            <img v-if="minimize" src="img/FHDO-Logo.svg" alt="FH-Logo" class="ml-1" height="12px">
            <img src="img/FHDO-Logo.svg" alt="FH-Logo" class="ml-3 c-sidebar-brand-full" height="32px">
        </CSidebarBrand>
        <CRenderFunction flat :content-to-render="navbar"/>
        <CSidebarMinimizer
            class="d-md-down-none"
            @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
        />
    </CSidebar>
</template>

<script>

export default {
    name: 'TheSidebar',
    computed: {
        show() {
            return this.$store.state.sidebarShow;
        },
        minimize() {
            return this.$store.state.sidebarMinimize;
        },
        items: {
            get(){
                return this.$store.getters["user/navbar"];
            },
            set(){
            }
        },
        navbar(){
            let nav = this.items;
            nav._children = nav._children.map(item => {
                if(item.name)
                    item.name = this.$t(item.name);
                else
                    item._children[0] = this.$t(item._children[0]);
                return item;
            })
            return [nav];
        }
    }
};
</script>
