<template>
    <CFooter :fixed="false">
        <div>
            <a href="https://fh-dortmund.de" target="_blank">FH-Dortmund</a>
            <span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
        </div>
        <div class="mfs-auto">
            <CLink v-b-modal.about-footer-modal>{{ $t('generally.about.button') }}</CLink>
        </div>
        <b-modal
            :title="$t('generally.about.title')"
            id="about-footer-modal"
            size="lg"
            ok-only
        >
            {{ $t('generally.about.message') }}
            Powered by Marius Rasche &amp; Tobias Pleuger
        </b-modal>
    </CFooter>
</template>

<script>
export default {
    name: 'TheFooter'
};
</script>
